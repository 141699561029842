.clients{
    position: relative;
    padding: 96px 0;
    text-align: center;
    background-color: #FFFEF6;
    @include lg{
        padding: 50px 0;
    }
    img{
        margin-top: 48px;
        width: 60%;
        object-fit: contain;
        object-position: center;
        @include md {
            width: 80%;
        }
    }
    .all-client-btn{
      position: relative;
      height: 32px;
      color: $p2;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #8f8f8f40;
      width: max-content;
      left: 50%;
      transform: translateX(-50%);
      svg{
          font-size: 14px;
      }
      span{
          margin-left: 8px;
      }
    }
    .react-multi-carousel-list{
        width: 100%;
    }
}