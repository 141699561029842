.lets-talk{
    position: relative;
    padding: 96px 0;
    border-radius: 0 !important;
    @include lg{
        padding: 50px 0;
    }
    .left{
        h1{
            font-size: 52px;
            color: $n2;
            font-weight: 700;
            margin-bottom: 72px;
            @include lg{
                margin-bottom: 48px;
            }
            @include sm{
                font-size: 40px;
            }
            span{
                color: #3F3F3F;
            }
        }
        h2{
            font-size: 32px;
            font-weight: 700;
            color: $n2;
            margin-bottom: 36px;
            @include sm {
                font-size: 20px;
            }
        }
        .info{
            margin-bottom: 36px;
            display: flex;
            align-items: center;
            @include sm {
                align-items: flex-start;
                margin-bottom: 24px;
            }
            img{
                margin-right: 12px;
                height: 50px;
                @include sm {
                    height: 25px;
                }
            }
            p{
                font-size: 26px;
                color: $n2;
                line-height: 1.5;
                @include sm {
                    font-size: 16px;
                }
             }

        }
    }
    .form{
        .input-group{
            display: flex;
            flex-direction: column;
            width: 100%;
            &:not(:last-child){
                margin-bottom: 48px;
                @include sm {
                    margin-bottom: 24px;
                }
            }
            label{
                font-size: 14px;
                color: $p2;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 8px;
            }
            input{
                width: 100%;
                border: 1px solid rgba(143, 143, 143, 0.4);
                height: 56px;
            }
            textarea{
                width: 100%;
                border: 1px solid rgba(143, 143, 143, 0.4);
                padding: 16px;
            }
        }
        button{
            background-color: $p1;
            color: $p2;
            height: 56px;
            border: none;
            padding: 14px 24px;
            font-size: 18px;
            font-weight: 700;
        }
    }
    .right-txt{
        transform: rotate(-90deg);
        position: absolute;
        top: 200px;
        right: 0px;
        color: $p2;
        text-transform: uppercase;
        font-size: 12px;
    }
}