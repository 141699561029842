.elegro{
    position: relative;
    background-color: #e8ebe6;
    .elegro-title{
        font-size: 52px;
        font-weight: 700;
        padding-right: 6%
          }
    .elegro-para{
        font-size: 18px;
        line-height: 30px;
    }
    .elegro-btn {
      height: 56px;
      background-color: $p1;
      color: $p2;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: max-content;
      border-radius: 0 !important;
      span {
        margin-left: 6px;
        font-size: 18px;
        font-weight: 500;
      }
    };
}