.about {
  position: relative;
  padding: 96px 0;
  background-color: #292929;
  @include lg {
    padding: 50px 0;
  }
  .left {
    h1 {
      font-size: 52px;
      font-weight: 700;
      color: $p1;
      margin-bottom: 60px;
      @include sm{
        font-size: 40px;
      }
      span {
        color: $n1;
      }
    }
    img {
      width: calc(50% - 24px);
      object-fit: cover;
      object-position: center;
      @include sm{
        width: calc(50% - 12px);
      }
    }
    .interest {
      margin-top: 60px;
      h4 {
        font-size: 32px;
        font-weight: 700;
        color: $n1;
      }
      span {
        color: #4f4f4f;
        font-size: 21px;
        margin-top: 16px;
        text-transform: uppercase;
        &:not(:last-child) {
          margin-right: 1ch;
        }
      }
    }
  }
  .right {
    p {
      font-size: 21px;
      line-height: 1.5;
      color: $n1;
    }
    .about-skills {
      .single-skill {
        flex: 0 0 calc(50% - 24px);
        &:nth-child(odd){
            margin-right: 48px;
        }
        h3 {
          color: $n1;
          font-size: 50px;
          font-weight: 700;
          @include sm{
            font-size: 40px;
          }
        }
        p {
          font-size: 16px;
        }
      }
    }
    .interest {
      margin-top: 60px;
      h4 {
        font-size: 32px;
        font-weight: 700;
        color: $n1;
      }
      span {
        color: #4f4f4f;
        font-size: 21px;
        margin-top: 16px;
        text-transform: uppercase;
        &:not(:last-child) {
          margin-right: 1ch;
        }
        @include sm{
            font-size: 16px;
        }
      }
    }
  }
}
