/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Space+Grotesk:wght@400;500;700&display=swap');

/* fonts family */
$LibreBaskerville : 'Libre Baskerville', serif;
$spaceGrotesk: 'Space Grotesk', sans-serif;

/* Breakpoints */
@mixin xxl {
  @media only screen and (max-width: "1399px") {
    @content;
  }
}

@mixin xl {
  @media only screen and (max-width: "1199px") {
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: "991px") {
    @content;
  }
}

@mixin md {
  @media only screen and (max-width: "767px") {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width: "575px") {
    @content;
  }
}


/* colors */
//primary color
$p1 : #FFE500;
$p2: #000000;

//neutral color
$n1: #ffffff;
$n2: #212121;
$n3: #FFFAF5;

/* includes */
.bodyLoaderWrap{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loaderWrap{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-cursor{
  @media only screen and (max-width: "991px") {
    display: none;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: #4f4f4f;
    &:not(:last-child) {
      margin-right: 16px;
    }
    &.active,
    &:hover {
      color: #212121;
    }
  }
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}