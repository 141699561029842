.hero {
  position: relative;
  .mbl-screen {
    .top {
      background-color: #f1ebe5;
      padding-bottom: 20px;
      .logo {
        img {
          height: 39px;
        }
      }
      .talk-btn {
        background-color: $p1 !important;
        color: $p2;
        line-height: 1.2;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 !important;
        padding-left: 16px;
        padding-right: 16px;
        span {
          margin-right: 8px;
          font-size: 19px;
          font-weight: 500;
        }
      }
      .video {
        position: relative;
        .play_btn {
          position: absolute;
          display: block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(255, 255, 255, 0.1);
          width: 96px;
          height: 96px;
          border-radius: 100px;
          text-align: center;
          line-height: 96px;
          display: block;
          z-index: 9999;
          img {
            width: 50px;
            position: relative;
            z-index: 999;
            margin-top: 22px;
          }
        }
        video {
          width: 100%;
        }
      }
      .menu {
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 32px;
        button,
        a {
          color: $p2;
          font-weight: 700;
          border: none;
          background-color: transparent;
          &:not(:first-child) {
            font-weight: 400;
          }
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }
    .bottom {
      position: relative;
      background-color: $p1;
      padding-top: 32px;
      .pic {
        z-index: 1;
        img {
          height: 210px;
          width: 180px;
          object-fit: cover;
          justify-content: center;
          margin-right: 16px;
        }
      }
      .skills-wrap {
        padding: 16px 0;
        z-index: 2;
        h4 {
          font-family: $LibreBaskerville;
          font-size: 16px;
          font-style: italic;
          color: $n2;
          @media screen and (max-width: 370px) {
            font-size: 13px;
          }
        }
        .skills {
          position: relative;
          margin-top: 20px;
          font-size: 21px;
          font-weight: 700;
          color: $p2;
          @media screen and (max-width: 414px) {
            font-size: 16px;
            h1{
              line-height: 24px;
            }
          }
        }
        .time-frame {
          margin-top: 48px;
          p {
            position: relative;
            color: $p2;
            font-size: 10px;
            padding-left: 5ch;
            font-weight: 700;
            letter-spacing: 2px;
            &::after {
              position: absolute;
              height: 1px;
              width: 117px;
              content: "";
              bottom: 5px;
              left: -95px;
              background-color: #818c89;
            }
          }
        }
      }
      .scroll-wrap {
        position: relative;
        .scroll-down {
          position: relative;
          background-color: #818c89;
          background-color: #818c89;
          color: $n1;
          padding: 90px 24px 12px 12px;
          margin-top: -72px;
          margin-left: -12px;
          font-size: 11px;
          text-transform: uppercase;
          line-height: 1.5;
          .scroll-txt {
            position: relative;
            animation: scroll 2s linear infinite forwards;

            @keyframes scroll {
              0% {
                top: -5px;
                opacity: 0.3;
              }
              50% {
                top: 5px;
                opacity: 1;
              }
              100% {
                top: -5px;
                opacity: 0.3;
              }
            }
          }
          svg {
            font-size: 16px;
            margin-top: 4px;
          }
        }
        .name {
          display: flex;
          align-items: center;
          h1 {
            position: relative;
            font-family: $LibreBaskerville;
            font-size: 28px;
            color: $p2;
            margin-left: 12px;
            span {
              font-family: $LibreBaskerville;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .large-screen {
    height: 750px;
    @include xxl {
      height: 680px;
    }
    @include xl {
      height: 600px;
    }
    .bg-wrap {
      position: absolute;
      display: flex;
      height: 100%;
      width: 100%;
      z-index: -1;
      .left {
        flex: 0 0 50%;
        background-color: $p1;
      }
      .right {
        flex: 0 0 50%;
        background-color: #f1ebe5;
      }
    }
    .hero-wrap {
      .left {
        height: 750px;
        @include xxl {
          height: 680px;
        }
        @include xl {
          height: 600px;
        }
        background-color: $p1;
        .wrap {
          height: inherit;
          .logo {
            img {
              height: 50px;
            }
          }
          .skills-wrap {
            h4 {
              font-family: $LibreBaskerville;
              font-size: 16px;
              font-style: italic;
              color: $n2;
            }
            .skills {
              position: relative;
              margin-top: 20px;
              font-size: 40px;
              font-weight: 700;
              color: $p2;
              h1{
                line-height: 60px;
              }
              @include xxl {
                font-size: 34px;
                h1{
                  line-height: 48px;
                }
              }
              @include xl {
                font-size: 28px;
                h1{
                  line-height: 40px;
                }
              }
            }
          }
          .pic-wrap {
            .pic {
              height: 210px;
              width: 180px;
              position: relative;
              img {
                height: 210px;
                width: 180px;
                object-fit: cover;
                justify-content: center;
                transition: all .5s linear;
                position: absolute;
                left: 0;
                top: 0;
              }
              .pic-two{
                opacity: 0;
              }
              &:hover{
                .pic-one{
                  opacity: 0;
                }
                .pic-two{
                  opacity: 1;
                }
              }
            }
            .icons-wrap{
              display: flex;
              flex-direction: column;
              align-items: center;
              .icons {
                display: flex;
              align-items: center;
              svg {
                margin-left: 16px;
                color: $p2;
                font-size: 24px;
              }
              .icons-fiverr{
                position: relative;
                svg{
                  font-size: 60px;
                }
              }
            }
          }
            .scroll-wrap {
              display: flex;
              align-items: flex-start;
              margin-top: 20px;
              p {
                font-family: $LibreBaskerville;
                font-style: italic;
                font-weight: 300;
                color: $p2;
                margin-right: 16px;
                flex: none;
                font-size: 14px;
                @include xl {
                  font-size: 14px;
                  margin-right: 8px;
                }
              }
              .scroll {
                position: relative;
                padding-bottom: 16px;
                padding-left: 16px;
                width: 100%;
                .scroll-down {
                  font-weight: 700;
                  font-size: 11px;
                  color: $p2;
                  margin-left: 32px;
                  top: 36px;
                  position: relative;
                  animation: scroll 2s linear infinite forwards;
                  @keyframes scroll {
                    0% {
                      top: -5px;
                      opacity: 0.3;
                    }
                    50% {
                      top: 5px;
                      opacity: 1;
                    }
                    100% {
                      top: -5px;
                      opacity: 0.3;
                    }
                  }
                  p {
                    font-style: normal;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-family: $spaceGrotesk;
                    margin-bottom: 4px;
                    letter-spacing: 1px;
                    line-height: 14px;
                  }
                  span {
                    font-size: 18px;
                  }
                }

                .quote {
                  position: absolute;
                  right: -90px;
                  bottom: 70px;
                  svg {
                    font-size: 24px;
                    color: #818c89;
                  }
                }
                .line {
                  position: absolute;
                  height: 1px;
                  width: 99px;
                  bottom: -18px;
                  right: -120px;
                  background-color: #818c89;
                  @include xl{
                    bottom: -14px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .hero-details {
      background-color: #f1ebe5;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      .top {
        .menu {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          button,
          a {
            color: $n2;
            font-size: 20px;
            font-weight: 600;
            border: none;
            background-color: transparent;
            &:not(:last-child) {
              margin-right: 48px;
            }
          }
        }
        .talk-btn {
          background-color: $p1 !important;
          border-radius: 0 !important;
          color: $p2;
          line-height: 1.2;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 20px;
          padding-right: 20px;
          span {
            margin-right: 8px;
            font-size: 19px;
            font-weight: 500;
          }
        }
        .video {
          position: relative;
          padding-left: 36px;
          .play_btn {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(255, 255, 255, 0.1);
            width: 96px;
            height: 96px;
            border-radius: 100px;
            text-align: center;
            line-height: 96px;
            display: block;
            z-index: 999;
            img {
              width: 50px;
              position: relative;
              z-index: 999;
              margin-top: 22px;
            }

            /* ------------Waves-Animation---------------- */
            .waves-block {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 150px;
              height: 150px;
            }

            .waves {
              width: 150px;
              height: 150px;
              background: rgba(255, 255, 255, 0.3);
              opacity: 0;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              border-radius: 100%;
              z-index: -1;
              -webkit-animation: waves 3s ease-in-out infinite;
              animation: waves 3s ease-in-out infinite;
            }

            .wave-1 {
              -webkit-animation-delay: 0s;
              animation-delay: 0s;
            }

            .wave-2 {
              -webkit-animation-delay: 1s;
              animation-delay: 1s;
              position: absolute;
              top: 0;
            }

            .wave-3 {
              -webkit-animation-delay: 2s;
              animation-delay: 2s;
              position: absolute;
              top: 0;
            }

            @keyframes waves {
              0% {
                -webkit-transform: scale(0.2, 0.2);
                transform: scale(0.2, 0.2);
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              }

              50% {
                opacity: 0.9;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
              }

              100% {
                -webkit-transform: scale(0.9, 0.9);
                transform: scale(0.9, 0.9);
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              }
            }
          }
          video {
            width: 100%;
          }
        }
      }
      .bottom {
        position: relative;
        width: 100%;
        &::after {
          position: absolute;
          content: "";
          height: calc(100% + 63px);
          width: 1px;
          background-color: #818c89;
          top: -15px;
          left: 36px;
        }
        .time-frame {
          margin-left: 24px;
          p {
            font-size: 10px;
            font-weight: 700;
            color: $n2;
            span {
              padding: 15px 2px 15px 90px;
              background-color: $p1;
              margin-right: 4px;
            }
          }
        }
        .name {
          margin-top: 48px;
          display: flex;
          align-items: center;
          .line {
            position: relative;
            width: 92px !important;
            background-color: $p2;
            height: 1px;
          }
          h1 {
            font-family: $LibreBaskerville;
            text-transform: uppercase;
            font-size: 24px;
            margin-left: 16px;
            border-radius: 2px;
            color: $p2;
            display: flex;
            gap: 1ch;
            span {
              font-family: $LibreBaskerville;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .vjs-paused .vjs-big-play-button,
  .vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
  }
  
  .video-js {
    .vjs-big-play-button {
      line-height: 1.5em;
      height: 60px;
      width: 60px;
      left: calc(50% + 45px);
      top: calc(50% + 14px);
      transform: translate(-50%, -50%);
      border: 2px solid #fff;
      background-color: #ffe500;
      border-radius: 50%;
      &:focus{
        background-color: #ffe500 !important;
      }
      .vjs-icon-placeholder {
        &::before {
          color: #414141 !important;
          top: 7px;
        }
      }
    }
    &:hover{
      .vjs-big-play-button{
        background-color: #ffe500;
      }
    }
  }
}
