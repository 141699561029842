.portfolio-section {
  position: relative;
}
.portfolio-main {
  z-index: 99;
  .portfolio-title {
    font-size: 32px;
    font-weight: 700;
    @include lg {
      margin-top: 48px;
    }
  }
  .portfolio-menu {
    nav {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      @include lg {
        flex-wrap: wrap;
        gap: 32px;
      }
      .category-item {
        a {
          border: none;
          background-color: transparent;
          color: #4f4f4f;
          margin-bottom: 16px;
          &.active{
            color: $p2;
            font-weight: 700;
          }
        }
        &.active {
          button {
            color: $p2;
            font-weight: 700;
          }
        }
        &:not(:last-child) {
          margin-right: 32px;
          @include lg {
            margin-right: 0;
          }
        }
      }
    }
  }
  .portfolio-item {
    .wrap {
      position: relative;
      transition: all 1s;
      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .case-study-btn {
        position: absolute;
        height: 56px;
        background-color: $p1;
        padding: 8px 12px;
        color: $p2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        gap: 8px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
        span {
          margin-left: 6px;
          font-size: 18px;
          font-weight: 500;
        }
      }

      &:hover{
        .case-study-btn{
          opacity: 1;
          visibility: visible;
          bottom: 24px;
        }
      }
    }
    .title {
      font-size: 14px;
      color: #a6a6a6;
      font-weight: 400;
      margin-top: 24px;
    }
    .name {
      font-size: 20px;
      font-weight: 700;
      color: #212121;
      margin-top: 8px;
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: #4f4f4f;
      &:not(:last-child) {
        margin-right: 16px;
      }
      &.active,
      &:hover {
        color: #212121;
      }
    }
  }
}

.social {
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffface;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: 70vh;
  max-width: 100px;
  z-index: 9;
  a {
    color: $p2;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
.fslightbox-slide-btn-container {
  .fslightbox-svg-path {
    fill: #ffe500 !important;
  }
}

.portfolio-item {
  .wrap {
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .case-study-btn {
      position: absolute;
      height: 56px;
      background-color: $p2;
      padding: 8px 12px;
      color: $n1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
      span {
        margin-left: 6px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
  .title {
    font-size: 14px;
    color: #a6a6a6;
    font-weight: 400;
    margin-top: 24px;
  }
  .name {
    font-size: 20px;
    font-weight: 700;
    color: #212121;
    margin-top: 8px;
  }
}
