.sticky-nav {
  position: fixed;
  background-color: #f1ebe5ec;
  width: 100vw;
  z-index: 9;
  padding: 12px 0;
  display: none;
  .sticky-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hamburger-btn{
    padding: 0;
  }
  .talk-btn{
    background-color: #442A24 !important;
    border-radius: 0 !important;
    color: #ffffff;
    line-height: 1.2;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .hamburger {
    display: none;
    cursor: pointer;
    padding: 10px 35px 16px 0px;
    z-index: 99;
    span {
      cursor: pointer;
      border-radius: 1px;
      height: 3px;
      width: 35px;
      background: #1e272f;
      position: absolute;
      display: inline-block;
      transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
      &::before {
        cursor: pointer;
        border-radius: 1px;
        height: 3px;
        width: 18px;
        background: #1e272f;
        position: absolute;
        display: inline-block;
        content: "";
        top: -10px;
        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      &::after {
        cursor: pointer;
        border-radius: 1px;
        height: 3px;
        width: 25px;
        background: #1e272f;
        position: absolute;
        display: inline-block;
        content: "";
        bottom: -10px;
        transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    &.active {
      span {
        background-color: transparent;
        &::before {
          top: 0;
          width: 35px;
          transform: rotate(45deg);
        }
        &::after {
          top: 10px;
          width: 35px;
          transform: translateY(-10px) rotate(-45deg);
        }
      }
    }
    @include lg {
      display: block;
    }
  }

  .mbl-menu-wrap {
    position: fixed;
    left: -110vw;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-color: $n1;
    opacity: 0.9;
    transition: all 0.5s linear;
    .menu {
      padding: 10% 24px;
      a,
      button {
        border: none;
        background-color: transparent;
        color: $p2;
        font-weight: 700;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
    .contact-btn {
      display: block !important;
    }
    &.active {
      left: 0;
    }
    .mbl-menu-hide {
      position: absolute;
      top: 24px;
      right: 24px;
      i {
        font-size: 36px;
        color: #1e272f;
      }
    }
  }
  @include lg{
    display: block;
  }
}
