.client-reviews {
    position: relative;
    padding: 96px 0;
    background-color: $n3;
    &__header {
      text-align: center;
      margin-bottom: 4rem;
  
      h1 {
        font-size: 48px;
        color: $n2;
        margin-bottom: 0.5rem;
      }
  
      p {
        color: $n2;
        font-size: 24px;
        margin-top: 16px;
      }
    }
  
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
      margin-bottom: 4rem;
  
      @media (max-width: 1199px) {
        grid-template-columns: 1fr;
      }
    }
  
    &__left {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  
    &__logo {
      max-width: 100px;
    }
  
    &__testimonial {
      blockquote {
        font-size: 24px;
        color: $n2;
        margin-bottom: 2rem;
        line-height: 1.4;
      }
    }
  
    &__project-info {
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      gap: 12px;
      .project-name {
        font-weight: 600;
        color: $n2;
      }

      .project-line{
        width: 2px;
        height: 12px;
        border-radius: 2px;
        background-color: #e6e8ec;
      }
  
      .project-category {
        color: $n2;
      }
    }
  
    .case-study-btn {
        background-color: $p1;
        color: $p2;
        height: 56px;
        border: none;
        padding: 14px 24px;
        font-size: 18px;
        font-weight: 700;
  
      &:hover {
        opacity: 0.9;
      }
    }
  
    &__right {
      .video-container {
        aspect-ratio: 16/9;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: opacity 0.2s;
        overflow: hidden;
  
        &:hover {
          opacity: 0.9;
        }
  
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
      .play-btn {
        background: white;
        border: none;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        svg {
          width: 24px;
          height: 24px;
          margin-left: 4px;
        }
      }
    }
  
    &__clients {
  
      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
  
      @media (max-width: 480px) {
        grid-template-columns: 1fr;
      }
    }
  
    .client-button {
      border: none;
      padding-bottom: 24px;
      cursor: pointer;
      transition: transform 0.2s;
      text-align: left;
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: 12px;
      width: calc(100% - 24px);
      &:hover {
        transform: translateY(-2px);
      }
  
      &.active {
        transform: translateY(-2px);
        border-bottom: 2px solid #B1B5C3;
      }

      .client-img{
        img{
            height: 48px;
            width: 48px;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
        }
      }
  
      .client-info {
        .client-name {
          font-weight: 600;
          margin-bottom: 0.25rem;
        }
  
        .client-handle {
          font-size: 0.875rem;
          opacity: 0.8;
          margin-top: 8px;
        }
      }
    }
  }