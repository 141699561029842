
  .work-menu {
    position: fixed;
    height: 100vh;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 991px) {
      display: none;
    }
    .wrapper {
      position: relative;
      transform: rotate(270deg);
      transform-origin: left top;
      .wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        left: -54%;
        margin-top: 16px;
        a,
        button {
          color: $n2;
          background-color: transparent;
          border: none;
          &.active {
            color: $p2;
            font-weight: 700;
          }
          &:not(:last-child) {
            margin-right: 32px;
          }
        }
      }
    }
  }
