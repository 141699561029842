.works {
  position: relative;
  background-color: #fffaf5;
  padding: 96px 0;
  @include lg {
    padding: 50px 0;
  }
  .heading {
    h1 {
      font-size: 52px;
      font-weight: 700;
      color: $p2;
      span {
        color: #454747;
      }
      @include xl {
        font-size: 42px;
      }
      @include sm {
        font-size: 22px;
      }
    }
    .works-list {
      position: relative;
      .toggler {
        color: $p2;
        svg {
          font-size: 16px;
        }
      }
      .categories {
        &.lg-category {
          @include lg {
            display: none !important;
          }
        }
        button {
          color: $n2;
          border: none;
          background-color: transparent;
          padding: 0;
          a{
            color: $n2;
          }
          &:not(:last-child) {
            margin-right: 32px;
            @include lg {
              margin-bottom: 16px;
            }
            @include xl {
              margin-right: 24px;
            }
          }
        }
        @include lg {
          position: absolute;
          flex-direction: column;
          z-index: 9;
          right: 0;
          padding: 16px;
          width: max-content;
          top: 30px;
          align-items: flex-start !important;
          background-color: $n1;
          &.active {
            display: flex !important;
            flex-direction: column;
            a {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
  .works-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    .single-works {
      position: sticky;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: $n1;
      padding-right: 12px;
      height: 100%;
      &._01 {
        z-index: 2;
      }
      &._02 {
        z-index: 1;
        bottom: 5%;
      }
      &._03 {
        z-index: 0;
        bottom: 0%;
      }
      @include lg {
        flex-direction: column !important;
        height: 379px;
        justify-content: flex-end;
        padding-right: 0;
      }
      .works-details {
        position: relative;
        flex: 0 0 33.3333%;
        padding: 64px 8px 64px 64px;
        @include lg {
          flex: none;
          position: absolute;
          align-self: flex-start;
          width: 100%;
          padding: 16px;
          height: 100%;
        }
        .txt {
          .theme {
            color: $n2;
          }
          h1 {
            font-size: 52px;
            font-weight: 700;
            margin-top: 48px;
            color: $p2;
            line-height: 1.1;
            @include xl {
              font-size: 42px;
            }
          }
          .info {
            font-size: 21px;
            color: $n2;
            margin-top: 24px;
          }
          @include lg {
            h1 {
              margin-top: 8px;
              font-size: 32px;
            }
          }
        }
        .case-btn {
          margin-top: 92px;
          height: 56px;
          background-color: $p1;
          color: $p2;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          width: max-content;
          border-radius: 0 !important;
          span {
            margin-left: 6px;
            font-size: 18px;
            font-weight: 500;
          }
          @include lg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .works-img {
        flex: 0 0 67.6667%;
        overflow: hidden;
        position: relative;
        height: 100%;
        &._01 {
          background: linear-gradient(
              90deg,
              #fff 1%, transparent),
            url("../../../public/img/hero/work-1.png") no-repeat center / cover;
        }
        &._02 {
          background: linear-gradient(
              90deg,
              #fff 1%, transparent),
            url("../../../public/img/hero/work-2.png") no-repeat center / cover;
        }
        &._03 {
          background: linear-gradient(
              90deg,
              #fff 1%, transparent),
            url("../../../public/img/hero/Osiro.png") no-repeat center / cover;
            
        }
        @include lg {
          flex: none;
          height: 281px;
          width: 100%;
          &._01 {
            background: linear-gradient(
                180deg,
                #fff 1%, transparent),
              url("../../../public/img/hero/work-1.png") no-repeat center /
                cover;
          }
          &._02 {
            background: linear-gradient(
                180deg,
                #fff 1%, transparent),
              url("../../../public/img/hero/work-2.png") no-repeat center /
                cover;
          }
          &._03 {
            background: linear-gradient(
                180deg,
                #fff 1%, transparent),
              url("../../../public/img/hero/Redovate UI UX Design.webp") no-repeat center /
                cover;
          }
        }
      }
    }
  }
  .all-work-btn {
    position: relative;
    height: 32px;
    color: $p2;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #8f8f8f40;
    width: max-content;
    left: 50%;
    transform: translateX(-50%);
    svg {
      font-size: 14px;
    }
    span {
      margin-left: 8px;
    }
  }
}
