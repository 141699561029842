.portfolio-section {
  .portfolio-logo {
    height: 500px;
    @include lg {
      height: 50vh;
    }
    .container-fluid {
      background-color: $p1;
      height: 500px;
      @include lg {
        height: 50vh;
      }
    }
    .portfolio-menu {
      nav {
        display: flex;
        align-items: center;
        justify-content: center;
        @include lg {
          flex-wrap: wrap;
        }
        .category-item{
          a {
            border: none;
            background-color: transparent;
            color: #4f4f4f;
            margin-bottom: 16px;
          }
          &:not(:last-child) {
            margin-right: 32px;
            @include md {
              margin-right: 0;
            }
          }
        }
      }
    }
    .work-menu {
      position: absolute;
      height: 500px;
      @include lg {
        height: 50vh;
      }
      .wrapper {
        .wrap {
          a {
            &.active {
              color: $p2;
              font-weight: bold;
            }
          }
        }
      }
    }

    .portfolio-main {
      .logo-hero {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 500px;
        @include lg {
          height: 50vh;
        }
        h1 {
          color: $n2;
          font-weight: 700;
          font-size: 80px;
          @include lg {
            font-size: 60px;
          }
          @include sm {
            font-size: 40px;
          }
        }
        p {
          color: $n2;
          font-size: 32px;
          font-weight: 700;
          margin-top: 12px;
          @include lg {
            font-size: 24px;
          }
          @include lg {
            font-size: 20px;
          }
        }
      }
    }

    .social {
      background-color: $p2;
      height: 500px;
      margin-right: -12px;
      @include lg {
        height: 50vh;
      }
      a {
        color: $p1;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
.logo-animation-txt {
  margin-top: 96px;
  @include lg{
    margin-top: 0;
  }
  .txt {
    padding: 0 10%;
    margin-top: 96px;
    @include lg {
      padding: 0;
      margin-top: 24px;
    }
    h2 {
      font-size: 38px;
      font-weight: 700;
      color: $n2;
    }
    p {
      font-size: 22px;
      line-height: 1.5;
      color: $n2;
      margin-top: 20px;
    }
  }
  .video {
    padding: 0 10%;
    margin-top: 96px;
    @include lg {
      padding: 0;
      margin-top: 24px;
    }
    img {
      width: 100%;
      border-radius: 4px;
      object-fit: cover;
      object-position: center;
    }
  }
  
  .more-btn{
    margin-top: 16px;
    height: 44px;
    background-color: $p1;
    color: $p2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0 !important;
    span{
        margin-left: 6px;
        font-size: 18px;
        font-weight: 500;
    }
  }
}

.web-development-works{
  background-color: #e8ebe6;
  padding: 96px 0;
  .work{
    img{
      height: 470px;
      object-fit: cover;
      @include lg{
        height: auto;
      }
    }
    .work-category{
      font-size: 18px;
      color: #212121;
      font-family: $LibreBaskerville;
    }
    .work-title{
      font-size: 36px;
      font-weight: 600;
      color: #212121;
      line-height: 46px;
    }
    .web-development-btn {
      height: 56px;
      background-color: $p1;
      color: $p2;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: max-content;
      border-radius: 0 !important;
      span {
        margin-left: 6px;
        font-size: 18px;
        font-weight: 500;
      }
    };
  }
  .work-thin{
    margin-top: 100px;
    padding-right: 18%;
    img{
      height: 740px;
      object-fit: cover;
    }
    @include lg{
      margin-top: 0;
      padding-right: 0;
      img{
        height: auto;
      }
    }
  }
  .work-thin-2{
    margin-top: -370px;
    padding-left: 18%;
    img{
      height: 560px;
      object-fit: cover;
    }
    @include lg{
      margin-top: 0;
      padding-left: 0;
      img{
        height: auto;
      }
    }
  }
}
