.right-side-social {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffface;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 70vh;
    max-width: 100px;
    z-index: 99;
    @media screen and (max-width: 991px) {
        display: none;
    }
    a {
        color: $p2;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
}
