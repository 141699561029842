.capabilities {
  position: relative;
  padding: 96px 0;
  background-color: $n2;
  @include lg{
    padding: 50px 0;
  }
  .left {
    h1 {
      color: $p1;
      font-size: 52px;
      font-weight: 700;
      margin-bottom: 32px;
      @include lg{
        margin-bottom: 0;
      }
      @include sm{
        font-size: 40px;
      }
    }
    p{
        font-size: 32px;
        line-height: 1.5;
        color: $n1;
        padding-right: 10%;
        @include lg{
            padding-right: 0;
        }
        @include sm{
            font-size: 24px;
        }
    }
  }
  .right{
    ul{
        li{
            font-size: 32px;
            color: $n1;
            display: flex;
            align-items: center;
            font-weight: 400;
            @include sm{
                font-size: 24px;
            }
            &:not(:last-child){
                margin-bottom: 24px;
            }
            svg{
                font-size: 16px;
                margin-right: 8px;
            }
        }
    }
  }
}
