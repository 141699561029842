.footer{
    position: relative;
    padding: 96px 0 16px;
    background-color: $n2;
    @include lg{
        padding: 50px 0 16px;
    }
    .name{
        font-size: 80px;
        font-weight: 600;
        color: $n1;
        @include xl{
            font-size: 50px;
        }
        @include lg{
            font-size: 40px;
        }
        @include sm {
            font-size: 36px;
        }
    }
    .occ{
        font-size: 60px;
        font-weight: 300;
        color: $n1;
        @include xl{
            font-size: 40px;
        }
        @include lg{
            font-size: 28px;
        }
        @include sm {
            font-size: 32px;
        }
    }
    .download-btn{
        height: 56px;
        border: 1px solid $p1;
        color: $p1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        padding: 14px 24px;
        border-radius: 0 !important;
        transition: all .3s ease;
        svg{
            margin-left: 12px;
        }
        &:hover{
            color: $p2;
            background-color: $p1;
        }
    }

    .footer-menu{
        a,
        button{
            color: $p1;
            font-weight: 700;
            border: none;
            background-color: transparent;
            text-align: start;
            padding: 0;
            &:not(:last-child){
                margin-bottom: 24px;
            }
            @include sm {
                &:not(:last-child){
                    margin-bottom: 0;
                    margin-right: 20px;
                }
            }
        }
    }

    .single-social{
        color: $n1;
        .icon{
            font-size: 40px;
            @include sm {
                display: none;
            }
        }
        h1{
            font-size: 32px;
            margin-top: 24px;
        }
        p{
            margin-top: 12px;
        }
        &:hover{
            .icon{
                color: $p1;
            }
        }
    }

    .footer-bottom{
        border-top: 1px solid #8F8F8F40;
        color: #4f4f4f;
        p{
            text-align: center;
            font-size: 14px;
        }
    }

}